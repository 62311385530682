







































































































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import moment from 'moment';
import _ from 'lodash';
import LabelValue from '@/dialogs/LabelValue.vue';
import DTBannerInfo, { EBannerState } from '../models/DTBannerInfo';

@Component({
	filters: {
		moment: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L');
		},
		momentWithTime: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L LT');
		}
	},
	components: {
		LabelValue
	},
	watch: {}
})
export default class Banners extends Vue {
	data(): any {
		return {
			Backbone,
			search: '',
			EBannerState,
			statusItems: [
				{ text: 'Aktiv', value: EBannerState.Active },
				{ text: 'Inaktiv', value: EBannerState.Inactive },
				{ text: 'Markeret til sletning', value: EBannerState.Deleted }
			],
			bannerList: {
				headers: [
					{
						text: '',
						align: 'left',
						sortable: false
					},
					{
						text: 'Banners',
						align: 'left',
						sortable: true,
						value: 'Name'
					},
					{
						text: '',
						align: 'left',
						sortable: true,
						value: 'State'
					}
				]
			},

			banners: <DTBannerInfo[]>[],
			selectedBanner: <DTBannerInfo | null>null
		};
	}

	private bannerData: any = null;
	private formDisabled: boolean = true;
	private rules = {
		required: [(v: any) => !!v || 'Dette felt skal udfyldes!'],
		fileValidation: [
			(v: any) => {
				if (v == undefined) {
					return true;
				}
				if (v.size > 4000000) {
					return 'Filen er for stor';
				}

				return true;
			}
		]
	};

	async mounted() {
		await this.RefreshBanners();
	}

	private async RefreshBanners() {
		let response = await Backbone.RemoteActions.Banner.GetAll();
		if (response.status < 300) {
			this.$data.banners = response.data;
		}
	}

	async SelectBanner(banner: DTBannerInfo) {
		this.$data.selectedBanner = Object.assign({}, banner);
		this.formDisabled = true;
	}

	NewBanner() {
		this.$data.selectedBanner = new DTBannerInfo();
		this.formDisabled = false;
	}

	validateImageDimensions(file: File) {
		if (file) {
			var reader = new FileReader();
			reader.onload = () => {
				var img = new Image();
				img.onload = () => {
					if (img.width != 728 || img.height != 90) {
						Backbone.UI.PushAlert('Kan ikke uploade banneret. Dimensionerne på banneret skal være 728x90 pixels.', 'error', true);
						this.bannerData = null;
					}
				};
				img.src = reader.result!.toString();
			};
			reader.readAsDataURL(file);
		}
	}

	private BannerStateAsIcon(banner: DTBannerInfo) {
		switch (banner.State) {
			case EBannerState.Active:
				return '';
			case EBannerState.Inactive:
				return 'mdi-delete-off-outline';
			case EBannerState.Deleted:
				return 'mdi-email-outline';
		}

		return 'mdi-alert-outline';
	}

	async Delete() {
		Backbone.UI.Progress.Show('Sletter banner... ');
		let response = await Backbone.RemoteActions.Banner.Delete(this.$data.selectedBanner.Id);

		if (response.status < 300) {
			this.$data.selectedBanner = null;
			Backbone.UI.PushAlert('Banneret blev slettet', 'success', true);
			await this.RefreshBanners();
			Backbone.UI.Progress.Hide();
			return;
		} else {
			Backbone.UI.Progress.Hide();
			Backbone.UI.PushApiErrorAlert('Kunne ikke slette banneret', response, 'error', true);
		}
	}

	Reset() {
		const orgBanner = this.$data.banners.find((x: DTBannerInfo) => x.Id === this.$data.selectedBanner.Id);
		this.$data.selectedBanner = _.cloneDeep<DTBannerInfo>(orgBanner);
		this.formDisabled = true;
	}

	async SaveData() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}
		if (!this.bannerData && !this.$data.selectedBanner.DownloadKey) {
			Backbone.UI.PushAlert('Billedfil skal uploades', 'warning', true);
			return;
		}
		Backbone.UI.Progress.Show('Gemmer banner...');
		let bannerSaveResponse = await Backbone.RemoteActions.Banner.CreateOrPutBanner(this.$data.selectedBanner, this.bannerData);
		if (bannerSaveResponse.status < 300) {
			this.$data.selectedBanner = bannerSaveResponse.data;
			this.RefreshBanners();
			this.bannerData = null;
		} else {
			Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', bannerSaveResponse, 'error', true);
			return;
		}
		Backbone.UI.Progress.Hide();
		this.formDisabled = true;
	}
}
